import { resolveValue, Toaster } from "react-hot-toast";

const MessageToaster = () => (
  <Toaster
    toastOptions={{
      position: "bottom-center",
    }}
    containerStyle={{
      top: 0,
      left: 0,
      bottom: 20,
      right: 0,
    }}
  >
    {(t) => <div>{resolveValue(t.message, t)}</div>}
  </Toaster>
);

export default MessageToaster;
