import { getCookie, setCookie } from "cookies-next";
import PropTypes from "prop-types";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  FAVOURITE_ITEMS_COOKIE_MAX_AGE,
  FAVOURITE_ITEMS_STORAGE_KEY,
} from "~config/favouriteItems";
import * as analyticsEvents from "~lib/analytics/events";
import * as preprEvents from "~lib/prepr/analytics/events";

const updateFavouritesCookie = (favourites) => {
  setCookie(FAVOURITE_ITEMS_STORAGE_KEY, JSON.stringify(favourites), {
    maxAge: FAVOURITE_ITEMS_COOKIE_MAX_AGE || undefined,
  });
};

export const FavouriteItemsContext = createContext({});

export const FavouriteItemsProvider = ({ children }) => {
  const [favouriteItemIds, setFavouriteItemIds] = useState([]);
  const updateListeners = useRef([]);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const cookieFavouriteItems = getCookie(FAVOURITE_ITEMS_STORAGE_KEY);
    if (cookieFavouriteItems) {
      setFavouriteItemIds(JSON.parse(cookieFavouriteItems) || []);
    }
  }, []);

  const addFavouriteItems = useCallback(
    (itemIds, itemTitles = []) => {
      const favourites = favouriteItemIds || [];
      const updatedFavourites = [...favourites];

      itemIds.forEach((itemId, index) => {
        if (!favourites.includes(itemId)) {
          updatedFavourites.push(itemId);

          preprEvents.triggerBookmarkEvent(itemId);
          if (itemTitles[index]) {
            analyticsEvents.pushFavouriteEvent(itemTitles[index]);
          }
        }
      });

      updateListeners.current.forEach((listener) =>
        listener(updatedFavourites, favourites),
      );

      updateFavouritesCookie(updatedFavourites);
      setFavouriteItemIds(updatedFavourites);
    },
    [favouriteItemIds],
  );

  const addFavouriteItem = useCallback(
    (itemId, itemTitle = null) => {
      const favourites = favouriteItemIds || [];
      if (!favourites.includes(itemId)) {
        const updatedFavourites = [...favourites, itemId];
        updateFavouritesCookie(updatedFavourites);
        setFavouriteItemIds(updatedFavourites);
        updateListeners.current.forEach((listener) =>
          listener(updatedFavourites, favourites),
        );

        // trigger analytics events
        preprEvents.triggerBookmarkEvent(itemId);
        if (itemTitle) {
          analyticsEvents.pushFavouriteEvent(itemTitle);
        }
      }
    },
    [favouriteItemIds],
  );

  const removeFavouriteItem = useCallback(
    (itemId, itemTitle = null) => {
      if (favouriteItemIds?.includes(itemId)) {
        const updatedFavourites = favouriteItemIds.filter(
          (id) => id !== itemId,
        );
        updateFavouritesCookie(updatedFavourites);
        setFavouriteItemIds(updatedFavourites);
        updateListeners.current.forEach((listener) =>
          listener(updatedFavourites, favouriteItemIds),
        );

        // trigger analytics events
        preprEvents.triggerUnbookmarkEvent(itemId);
        if (itemTitle) {
          analyticsEvents.pushUnfavouriteEvent(itemTitle);
        }
      }
    },
    [favouriteItemIds],
  );

  const toggleFavouriteItem = useCallback(
    (itemId, itemTitle = null) => {
      if (favouriteItemIds?.includes(itemId)) {
        removeFavouriteItem(itemId, itemTitle);
      } else {
        addFavouriteItem(itemId, itemTitle);
      }
    },
    [favouriteItemIds, addFavouriteItem, removeFavouriteItem],
  );

  const addOnChangeListener = useCallback((listener) => {
    updateListeners.current.push(listener);
    return () => {
      updateListeners.current = updateListeners.current.filter(
        (l) => l !== listener,
      );
    };
  }, []);

  const value = useMemo(
    () => ({
      favouriteItemIds: favouriteItemIds || [],
      toggleFavouriteItem,
      addFavouriteItem,
      addFavouriteItems,
      removeFavouriteItem,
      onFavouritesChange: addOnChangeListener,
    }),
    [
      favouriteItemIds,
      addFavouriteItem,
      addFavouriteItems,
      removeFavouriteItem,
      toggleFavouriteItem,
      addOnChangeListener,
    ],
  );

  return (
    <FavouriteItemsContext.Provider value={value}>
      {children}
    </FavouriteItemsContext.Provider>
  );
};

FavouriteItemsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
