import getConfig from "next/config";
import React from "react";

import packageInfo from "../../../package.json";
import classes from "./Watermark.module.scss";

const { publicRuntimeConfig } = getConfig();

const Watermark = () =>
  publicRuntimeConfig.environment &&
  publicRuntimeConfig.environment !== "production" ? (
    <div className={classes.watermark}>
      v{packageInfo.version} @ {publicRuntimeConfig.environment}
    </div>
  ) : null;

Watermark.propTypes = {};

export default Watermark;
