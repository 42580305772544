const triggerEvent = (name, details = {}) => {
  if (typeof window !== "undefined" && typeof window.prepr === "function") {
    window.prepr("event", name, details);
  }
};

export const triggerBookmarkEvent = (id) => {
  triggerEvent("Bookmark", { id });
};

export const triggerUnbookmarkEvent = (id) => {
  triggerEvent("Unbookmark", { id });
};
