/**
 * Apply the result of each function in the array to the next function in the array.
 *
 * @param {Array.<Function>} fns
 * @param {*} to
 * @returns {*}
 */
export const applyFns = (fns, to) => fns.reduce((acc, fn) => fn(acc), to);

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

export const pick = (obj, keys) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => keys.includes(key)));

export const omit = (obj, keys) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key)),
  );

/**
 * Test if two arrays have at least one item in common.
 *
 * @param {Array<any>} array1
 * @param {Array<any>} array2
 * @returns {boolean}
 */
export const doArraysIntersect = (array1, array2) =>
  array1.some((item1) => array2.includes(item1));

/**
 * @template T - generic type input
 * @param {T|Array<T>} value
 * @returns {Array<T>}
 */
export const wrapArray = (value) => {
  if (typeof value === "undefined") {
    return [];
  }
  return Array.isArray(value) ? value : [value];
};

export const snakeCaseToCamelCase = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z0-9])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", ""),
    );

export const filterObjectValues = (obj, filter) => {
  const filtered = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (filter(value)) {
      filtered[key] = value;
    }
  });
  return filtered;
};

export const arrayOfNumbers = (length) => Array.from({ length }, (_, i) => i);

export const isNumber = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(parseFloat(value));
};

// utils/textUtils.js or similar file

/**
 * Normalizes text by converting it to lowercase and removing all whitespace.
 * @param {string} text - The text to normalize.
 * @return {string} - The normalized text.
 */
export function normalizeText(text) {
  return text.toLowerCase().replace(/\s/g, "");
}
