import { useRouter } from "next/router";
import { node } from "prop-types";
import { createContext, useContext, useEffect, useState } from "react";

export const PRIMARY_THEME = "default";
const SECOND_THEME = "750";

export const allowedLanguages = ["nl", "en"];

const ThemeContext = createContext({
  theme: PRIMARY_THEME,
  toggleTheme: () => {},
});

export function useTheme() {
  return useContext(ThemeContext);
}

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(PRIMARY_THEME);

  const { locale: lang } = useRouter();

  useEffect(() => {
    const updateTheme = (newTheme) => {
      document.documentElement.setAttribute("data-theme", newTheme);
      if (typeof window !== "undefined" && window.localStorage) {
        window.localStorage.setItem("theme", newTheme);
      }
    };

    if (!allowedLanguages.includes(lang)) {
      updateTheme(PRIMARY_THEME);
      return;
    }

    const savedTheme = window.localStorage?.getItem("theme") || PRIMARY_THEME;
    setTheme(savedTheme);
    updateTheme(savedTheme);
  }, [lang]);

  const toggleTheme = () => {
    if (!allowedLanguages.includes(lang)) {
      return;
    }

    const newTheme = theme === PRIMARY_THEME ? SECOND_THEME : PRIMARY_THEME;
    setTheme(newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
    if (typeof window !== "undefined") {
      window.localStorage.setItem("theme", newTheme);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: node.isRequired,
};
