import { addDays } from "date-fns/addDays";
import { format } from "date-fns/format";

import { TIME_OF_DAY_QUICK_FILTERS } from "~config/constants";

export const pushAnalyticsEvent = async (eventName, details = {}) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "production") {
      console.log(`Pushing analytics event: ${eventName}`, details);
    }
    let isResolved = false;
    return new Promise((resolve) => {
      window.dataLayer.push({
        event: eventName,
        ...details,
        eventCallback: () => {
          if (!isResolved) {
            isResolved = true;
            resolve();
          }
        },
      });
      // if GA doesn't respond in 2 seconds, resolve anyway so the app won't indefinitely await
      setTimeout(() => {
        if (!isResolved) {
          isResolved = true;
          resolve();
        }
      }, 2000);
    });
  }
  return Promise.resolve();
};

export const pushEcommerceEvent = (eventName, details = {}) =>
  pushAnalyticsEvent(eventName, { ecommerce: details });

/**
 * @param {string} itemName
 */
export const pushFavouriteEvent = (itemName) =>
  pushAnalyticsEvent("favourite", { item_name: itemName });

/**
 * @param {string} itemName
 */
export const pushUnfavouriteEvent = (itemName) =>
  pushAnalyticsEvent("unfavourite", { item_name: itemName });

/**
 * @param {string} groupName
 * @param {string} tagName
 */
export const pushFilterOnEvent = (groupName, tagName) =>
  pushAnalyticsEvent("filter_on", {
    item_group: groupName,
    item_name: tagName,
  });

/**
 *
 * @param {string} groupName
 * @param {string} tagName
 */
export const pushFilterOffEvent = (groupName, tagName) =>
  pushAnalyticsEvent("filter_off", {
    item_group: groupName,
    item_name: tagName,
  });

/**
 * @param {string} categoryName
 */
export const pushCategorySelectEvent = (categoryName) =>
  pushAnalyticsEvent("category_select", { link_text: categoryName });

/**
 * @param {string} sortName
 */
export const pushSortEvent = (sortName) =>
  pushAnalyticsEvent("sort", { item_name: sortName });

/**
 * @param {Date} startDate
 * @param {number} rangeInDays
 * @param {string} filterName
 */
export const pushDateRangeFilterEvent = (
  startDate,
  rangeInDays,
  filterName = null,
) => {
  if (
    !(startDate instanceof Date) ||
    typeof rangeInDays !== "number" ||
    rangeInDays < 1
  ) {
    throw new Error("Invalid date range filter");
  }
  return pushAnalyticsEvent("date_range", {
    start: format(startDate, "yyyy-MM-dd"),
    end: format(addDays(startDate, rangeInDays - 1), "yyyy-MM-dd"),
    ...(filterName ? { item_name: filterName } : {}),
  });
};

export const pushDateClearFilterEvent = () => pushAnalyticsEvent("date_clear");

/**
 * @param {'today' | 'tomorrow' | 'weekend'} type
 * @throws {Error} if type is invalid
 */
export const pushDateTimeOfDayFilterEvent = (type) => {
  const options = Object.values(TIME_OF_DAY_QUICK_FILTERS);
  if (!options.includes(type)) {
    throw new Error(
      `Invalid time of day filter type: ${type}, options: ${options.join(", ")}`,
    );
  }
  return pushAnalyticsEvent("time_of_day", { item_name: type });
};

export const pushPageviewEvent = (props) => {
  pushAnalyticsEvent("page_view", props);
};
