export const headScript = () => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "development") {
    return (
      // eslint-disable-next-line @next/next/no-sync-scripts
      <script src="https://cdn.ablyft.com/s/77297557.js"></script>
    );
  }
  return (
    // eslint-disable-next-line @next/next/no-sync-scripts
    <script src="https://cdn.ablyft.com/s/38205936.js" />
  );
};
